<template>
  <task-launcher :tasks="tasks">
  </task-launcher>
</template>

<script>

export default {
  components: {
    TaskLauncher: () => import('@/components/task-launcher')
  },
  activated () {
    this.getTasks()
  },
  data () {
    return {
      tasks: []
    }
  },
  methods: {
    async getTasks () {
      const { data } = await this.$http().get('/core/orchestrator/tasks/treatment')
      this.tasks = data.tasks
    }
  }
}
</script>
